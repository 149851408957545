table {
    background-color: #5cdb95;
    margin-top: 2rem;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
}

tr {
    border-bottom: 1px solid #000 !important;
    /* background-color: white; */
}

tr td, tr th {
    border-right: 1px solid #000;
    padding: 0.5rem;
    color: #d63384;
}

tr:nth-child(even) {
    background-color: #D6EEEE;
  }