@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 5.5vh;
}

/* .logo {
} */

.menu-icon {
  display: none;
}
/* 
.nav-elements {
} */

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: auto;
  padding: 0px;
}

.nav-elements ul li {
  margin-left: 30px;
  margin-right: 30px;
  display: inline-block;
}

.nav-elements ul a {
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    left: 20px;
    position: absolute;
  }

  .nav-elements {
    position: absolute;
    left: 0;
    top: 60px;
    background-color: #05386b;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}


/* Custom CSS */
.nav-link.active{
    background-color: #5cdb95 !important;
}

.navbar {
    background-color: #05386b;
    position: fixed !important;
    width: 100%;
    transition: all 0.3s ease-in;
    z-index: 999;
}

.muted {
    background-color: #5cdb95;
    opacity: 0.6;
}

.muted .nav-link, .muted .menu-icon{
    color: #05386b !important;
}
.nav-link{
    color: #edf5e1 !important;
}

.nav-item{
    font-weight: bold;
    font-size: 1.2rem;
}


@media (max-width: 1400px) and (min-width: 1201px) {
    .nav-item .nav-link{
        font-size: 1.1rem;
        padding: 0px;
    }
}

@media (max-width: 1200px) and (min-width: 1001px){
    .nav-item .nav-link{
        font-size: 1rem;
        padding: 0px;
    }
}

@media (max-width: 1000px) and (min-width: 801px) {
    .nav-item .nav-link{
        font-size: 0.9rem;
        padding: 0px;
        margin: auto;
    }

    .nav-elements, .nav-elements ul, .nav-elements li{
        padding: 0px;
        margin: auto;
    }

    .nav-elements ul li {
        margin-left: 15px;
        margin-right: 15px;
    }
      
}

@media (max-width: 800px) {
    .nav-item .nav-link{
        font-size: 0.9rem;
        padding: 0px;
        margin: auto;
    }

    .nav-elements, .nav-elements ul, .nav-elements li{
        padding: 0px;
        margin: auto;
    }

    .nav-elements ul li {
        margin-left: 5px;
        margin-right: 5px;
    }
      
}